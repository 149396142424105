.App {
}

.App-logo {
  /*animation: App-logo-spin infinite 20s linear;*/
  height: 90px;
  margin-top: 10px;
}

.logo {
  text-align: center;
}

.App-header {
  background-color: #222;
  height: 120px;
  padding: 20px;
  color: white;
  text-align: center;
}

.App-title {
  font-size: 1.5em;
}

.App-intro {
  font-size: large;
}

@keyframes App-logo-spin {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}

body {
  background-color: #f0f2f5;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  margin-top: 20px;
}

.content .ant-card {
  margin-bottom: 20px;
}

button span {
  margin-bottom: 0;
}

.ant-card-body label:first-child {
  margin-top: 0;
}

.ant-card-body label {
  margin-top: 15px;
  margin-bottom: 5px;
  display: block;
}

.danger {
  color: red;
}

.success {
  color: green;
}


td span,
td input {
  margin-bottom: auto;
}
.editable-cell {
  position: relative;
}

.editable-cell-input-wrapper,
.editable-cell-text-wrapper {
  padding-right: 24px;
}

.editable-cell-text-wrapper {
  padding: 5px 24px 5px 5px;
}

.editable-cell-icon,
.editable-cell-icon-check {
  position: absolute;
  right: 0;
  width: 20px;
  cursor: pointer;
}

.editable-cell-icon {
  line-height: 18px;
  display: none;
}

.editable-cell-icon-check {
  line-height: 28px;
}

.editable-cell:hover .editable-cell-icon {
  display: inline-block;
}

.editable-cell-icon:hover,
.editable-cell-icon-check:hover {
  color: #108ee9;
}

.editable-add-btn {
  margin-bottom: 8px;
}
